<template>
  <div>
    <b-card-code
      title="Store AirWayBill And Send Pickup Request"
      :before-change="validationForm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Product Description"
                label-for="product_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Product Description"
                  rules="required"
                >
                  <b-form-input
                    id="product_description"
                    v-model="airWayBillData.product_description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Iphone ....."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Number Of Pieices"
                label-for="number_of_peices"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Number Of Pieices"
                  rules="required|integer"
                >
                  <b-form-input
                    id="number_of_peices"
                    type="number"
                    v-model="airWayBillData.number_of_peices"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="authPermissions.includes('add-price-to-air-way-bill')">
              <b-form-group
                label="Collect Price"
                label-for="cod_amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Collect Price"
                  rules="integer"
                >
                  <b-form-input
                    id="cod_amount"
                    type="number"
                    v-model="airWayBillData.cod_amount"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <app-collapse
                id="airWayBill-data"
                type="margin"
                class="mt-0 mb-0"
              >
                <!-- Sender Data -->
                <app-collapse-item title="Sender Data">
                  <div>
                    <!-- Row Loop -->
                    <b-row>
                      <!-- city -->
                      <b-col md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-group
                            label="City"
                            label-for="city"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              id="city"
                              label="name"
                              v-model="senderSelectedCity"
                              :state="
                                airWayBillData.sender.city_id === null
                                  ? false
                                  : true
                              "
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="citiesData"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Address -->
                      <b-col md="6">
                        <b-form-group
                          label="Address"
                          label-for="sender-address"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                          >
                            <b-form-input
                              id="sender-address"
                              v-model="airWayBillData.sender.address"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Street ..."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Person -->
                      <b-col md="4">
                        <b-form-group
                          label="Contact Person"
                          label-for="sender-contact-person"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Contact Person"
                            rules="required"
                          >
                            <b-form-input
                              id="sender-contact-person"
                              v-model="airWayBillData.sender.contact_person"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Ahmed"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Email -->
                      <b-col md="4">
                        <b-form-group label="Email" label-for="sender-email">
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              id="sender-email"
                              v-model="airWayBillData.sender.email"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Example@MobileMasr.com"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Phone" label-for="sender-phone">
                          <validation-provider
                            #default="{ errors }"
                            name="Phone"
                            rules="required|integer"
                          >
                            <b-form-input
                              id="sender-phone"
                              type="number"
                              v-model="airWayBillData.sender.phone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="xxxxxxxx"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- add new button -->
                  </div>
                </app-collapse-item>
                <!-- Receiver Data -->
                <app-collapse-item title="Receiver Data">
                  <div>
                    <!-- Row Loop -->
                    <b-row>
                      <!-- city -->
                      <b-col md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-group
                            label="City"
                            label-for="city"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              id="city"
                              label="name"
                              v-model="receiverSelectedCity"
                              :state="
                                airWayBillData.receiver.city_id === null
                                  ? false
                                  : true
                              "
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="citiesData"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Address -->
                      <b-col md="6">
                        <b-form-group
                          label="Address"
                          label-for="receiver-address"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                          >
                            <b-form-input
                              id="receiver-address"
                              v-model="airWayBillData.receiver.address"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Street ..."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Person -->
                      <b-col md="4">
                        <b-form-group
                          label="Contact Person"
                          label-for="receiver-contact-person"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Contact Person"
                            rules="required"
                          >
                            <b-form-input
                              id="receiver-contact-person"
                              v-model="airWayBillData.receiver.contact_person"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Ahmed"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Email -->
                      <b-col md="4">
                        <b-form-group label="Email" label-for="receiver-email">
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              id="receiver-email"
                              v-model="airWayBillData.receiver.email"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Example@MobileMasr.com"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Phone" label-for="receiver-phone">
                          <validation-provider
                            #default="{ errors }"
                            name="Phone"
                            rules="required|integer"
                          >
                            <b-form-input
                              id="receiver-phone"
                              type="number"
                              v-model="airWayBillData.receiver.phone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="xxxxxxxx"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- add new button -->
                  </div>
                </app-collapse-item>
              </app-collapse>
            </b-col>

            <!-- submit button -->
            <b-col class="my-1">
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
  <script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
  },

  mixins: [heightTransition],
  watch: {
    senderSelectedCity(newCity) {
      this.airWayBillData.sender.city_id = newCity ? newCity.id : null
    },
    receiverSelectedCity(newCity) {
      this.airWayBillData.receiver.city_id = newCity ? newCity.id : null
    },
  },
  data() {
    return {
      authPermissions: [],
      citiesData: [],
      senderSelectedCity: null,
      receiverSelectedCity: null,
      airWayBillData: {
        product_description: null,
        number_of_peices: null,
        cod_amount: 0,
        receiver: {
          city_id: null,
          email: null,
          contact_person: null,
          address: null,
          phone: null,
        },
        sender: {
          city_id: null,
          email: null,
          contact_person: null,
          address: null,
          phone: null,
        },
      },

      errors_back: '',
      showDismissibleAlert: false,
      required,
    }
  },
  async created() {
    this.authPermissions = this.$store.state.authPermissions
    // fetch index of cities
    // fetch index of cities
    await this.getCities()
  },
  methods: {
    async getCities() {
      await axios
        .get('cities')
        .then((result) => {
          this.citiesData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // submit area API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('airWayBills/createAWBAndPickup', this.airWayBillData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              
              this.$router.push({
                path: '/AirWayBill/index',
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                console.log(err.response.data.data)
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // REPETED FORM
    repeateAgain() {
      this.data.areas.push({
        name: {
          en: '',
          ar: '',
        },
      })
      this.citySelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.activeSelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.areas.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.areas.splice(index, 1)
        this.citySelect.splice(index, 1)
        this.activeSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  ​
  <style lang="scss">
</style>
  ​
  <style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  transition: 0.35s height;
}
</style>